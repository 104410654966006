document.addEventListener('DOMContentLoaded', function() {
    var main_color = '#2d313f',
        saturation_value = -20,
        brightness_value = 5;
    var style = [{
        //set saturation for the labels on the map
        elementType: "labels",
        stylers: [{ saturation: saturation_value }]
    }, { //poi stands for point of interest - don't show these lables on the map 
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
    }, {
        //don't show highways lables on the map
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [{ visibility: "off" }]
    }, {
        //don't show local road lables on the map
        featureType: "road.local",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
    }, {
        //don't show arterial road lables on the map
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
    }, {
        //don't show road lables on the map
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }]
    },
    //style different elements on the map
    {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "poi.government",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "poi.attraction",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "poi.business",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "transit.station",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "landscape",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]

    }, {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }, {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ hue: main_color }, { visibility: "on" }, { lightness: brightness_value }, { saturation: saturation_value }]
    }];
    var mapOptions = {
        center: { lat: 35.5863939, lng: -5.3402745 },
        zoom: 16,
        scrollwheel: false,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: style
    }
    var map = new google.maps.Map(document.getElementById('google-container'), mapOptions);
    var latitude = 35.5863939;
    var longitude = -5.3402745;
    var myLatlng = new google.maps.LatLng(latitude, longitude);
    var marker_image = '/images/icons/map-marker.gif';
    var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: '',
        icon: marker_image
    });
    var infowindow = new google.maps.InfoWindow({ content: '<p>Bienvenue chez Devinweb</p>' });
    google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
    });
    var zoomControlDiv = document.createElement('div');
    CustomZoomControl(zoomControlDiv, map);
    //insert the zoom div on the top left of the map
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);
    function CustomZoomControl(controlDiv, map) {
        var controlUIzoomIn = document.getElementById('cd-zoom-in'),
            controlUIzoomOut = document.getElementById('cd-zoom-out');
        controlDiv.appendChild(controlUIzoomIn);
        controlDiv.appendChild(controlUIzoomOut);
        // Setup the click event listeners and zoom-in or out according to the clicked element
        google.maps.event.addDomListener(controlUIzoomIn, 'click', function () {
            map.setZoom(map.getZoom() + 1);
        });
        google.maps.event.addDomListener(controlUIzoomOut, 'click', function () {
            map.setZoom(map.getZoom() - 1);
        });
    }
})
