import { createApp } from "vue";

document.addEventListener("DOMContentLoaded", function () {
    createApp({
        data() {
            return {
                form: {
                    nom: "",
                    email: "",
                    objet: "",
                    message: "",
                    phone: "",
                },
                loading: false,
                errors: {},
                success: false,
            };
        },
        methods: {
            async send() {
                this.loading = true;
                this.success = false;
                this.errors = {};
                try {
                    await axios.post("/contact", this.form);
                    this.form.nom = "";
                    this.form.email = "";
                    this.form.phone = "";
                    this.form.objet = "";
                    this.form.message = "";
                    this.success = true;
                } catch (error) {
                    if (error?.response?.status === 422) {
                        this.errors = error?.response?.data?.errors || {};
                    }
                } finally {
                    this.loading = false;
                }
            },
        },
    }).mount("#cont");
});
